$gutter: 16px;
$primary: #f7545f;
$lightgrey: #676f78;

.main-wrapper {
  margin: 0 auto;
  background: #fff;
  max-width: 500px;
  padding: $gutter;
  top: 50%;

  p {
    line-height: 24px;
    word-break: break-word;
  }

  header {
    text-align: center;
    padding: $gutter * 4 0;
    margin-bottom: $gutter;
  }

  .info {
    margin-bottom: $gutter * 2;
    h1 {
      margin: 0 0 $gutter 0;
    }
    p {
      color: $lightgrey;
    }
  }

  form {
    label,
    input {
      display: block;
      font-size: 1rem;
    }

    label {
      margin-bottom: $gutter / 2;
      font-weight: 500;
      color: $primary;
    }

    input {
      width: 100%;
      margin-bottom: $gutter;
      padding: $gutter;
      border: none;
      border-bottom: 1px solid #d8d8d8;
    }
  }

  .button {
    padding: $gutter * 1.2 $gutter;
    display: block;
    width: 100%;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    border-radius: 6px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    color: white;
    background-color: $primary;
    &:disabled {
      background-color: lighten($primary, 20);
      cursor: auto;
    }
    &:hover {
      &:not(:disabled) {
        cursor: pointer;
        background-color: darken($primary, 20);
      }
    }
  }

  .errors {
    width: 100%;
    margin-top: $gutter;
    text-align: center;
    background: #d62828;
    padding: $gutter / 2;
    color: #fff;
    a {
      color: #fff;
    }
  }

  .redirect-notice {
    header {
      text-align: center;
      padding: 0;
    }
    a {
      color: $primary;
      font-weight: 900;
    }
  }
}

footer {
  color: $lightgrey;
  text-align: center;
  width: 100%;
  padding: $gutter;
  a {
    color: darken($lightgrey, 20);
  }
}
